import React, {
    Component
} from "react";

import "./Waves.css";


class Poolies extends Component {

  constructor(props) {
      super(props);
      this.state = {
          users: props.users,
          poolName: null
      };

  }

  async componentDidMount() {
    const interval = setInterval(this.getPoolies, 10000);

  }

  componentWillUnmount(){
    clearInterval(this.interval);

  }

  getPoolies = async () => {
    let apiAddress = this.props.apiAddress;
    const response = await fetch(`${apiAddress}api/get_users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        pool: this.props.poolName,
      }),
    });
    let body = await response.text();
    let resultObj = JSON.parse(body);
    this.setState({
      users: resultObj.users,
    });
  }



  render() {

    let displayNames = this.state.users;

    if(this.state.users != null && this.state.users != []){
      displayNames = this.state.users;
    }
    let users = [];
    if(displayNames!= null && displayNames != []){
      users = displayNames.map((poolie, key) => <li key = {key}> {poolie} < /li>);
    }

    return (<div>

      {
        this.props.poolName && (
            <div>{this.props.poolName}
              <ul>{users}</ul>
            </div>)
      }


      </div>);
  }
}

export default Poolies;
