import React, {
    Component
} from "react";

import wave from "./assets/Wave-1.svg";
import wave2 from "./assets/Wave-2.svg";

import "./Waves.css";


class Waves extends Component {
  render() {
    return (
      <div className="waveGroup">
        <img src = {wave} className = "wave1" />
        <img src = {wave2} className = "wave2" />
        <img src = {wave} className = "wave3" />
      </div>
    );
  }
}

export default Waves;
