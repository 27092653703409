import React, {
    Component
} from "react";
import * as $ from "jquery";
import hash from "./hash";
import ReactGA from 'react-ga';
import Poolies from "./Poolies";
import PoolControls from "./PoolControls";
import Logo from "./Logo";
import "./App.css";
import WaveGroup from "./WaveGroup";

ReactGA.initialize('UA-176250780-2');
var Promise = require('bluebird');

const scopes = [
    "user-library-read",
    "user-read-private",
    "user-read-email",
    "user-read-playback-state",
    "user-top-read",
    "playlist-modify-private",
    "playlist-modify-public",
    "playlist-read-collaborative",
    "user-top-read",
    "user-read-currently-playing",
    "user-read-playback-state",
];




const SERVER_API_ADDRESS = process.env.REACT_APP_SERVER_API_ADDRESS || 'https://spotipool-api-serverless.vercel.app/';
const AUTH_ENDPOINT = process.env.REACT_APP_AUTH_ENDPOINT || 'https://accounts.spotify.com/authorize';
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '7ef49475e2934f3ea3c0cfb0ba97934c';
const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL || "http://localhost:3000/redirect";



class App extends Component {
    constructor() {
        super();
        this.state = {
          poolNameError: false,
            displayName: null,
            playlistUrl: null,
            songsSent: false,
            artistsSent: false,
            artists: null,
            songs: {},
            pool: null,
            poolies: [],
            post: null,
            poolId: null,
            token: null,
            items: [],
            item: {
                album: {
                    images: [{
                        url: ""
                    }]
                },
                name: "",
                artists: [{
                    name: ""
                }],
                duration_ms: 0
            },
            is_playing: "Paused",
            progress_ms: 0,
            no_data: false,
        };

    }



    async componentDidMount() {
        // Set token
        let _token = hash.access_token;

        let _pool = hash.pool;

        if(!_token && window.localStorage.getItem('token')){
            await this.getUserId(window.localStorage.getItem('token'));
            if(this.state.userId != null){
              _token = window.localStorage.getItem('token');
            }
        }

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let poolQuery = params.get('pool');

        if(poolQuery){
          _pool = poolQuery;
        }

        if(_pool){
          window.localStorage.setItem('poolName', _pool);
        }

        if (_token) {
            // Set token
            this.setState({
                token: _token
            });
            this.getTopTrack(_token);
            this.getUserId(_token);
            this.getTopArtists(_token);
            window.localStorage.setItem('token', _token);

            if(window.localStorage.getItem('poolName') != null){
              await this.setState({
                post: window.localStorage.getItem('poolName'),
              });
            await this.getUserId(_token);

            await this.joinPool();
          }
        }


    }

    componentWillUnmount() {
        // clear the interval to save resources
        clearInterval(this.interval);
    }



    async getUserId(token){
      // Make a call using the token
      await $.ajax({
          url: "https://api.spotify.com/v1/me",
          type: "GET",
          beforeSend: xhr => {
              xhr.setRequestHeader("Authorization", "Bearer " + token);
          },
          success: async data => {
              // Checks if the data is not empty
              if (!data) {
                  this.setState({
                      no_data: true,
                  });
                  return;
              }

              await this.setState(
                {
                  userId: data.id,
                  displayName: data.display_name
              });
              return data.id;
          },
          error: data => {
            this.setState({userId: null});
          }
      });
    }

    async getTopTrack(token) {
        // Make a call using the token
        await this.getTopTracksForTimePeriod(token, 'long_term');
        await this.getTopTracksForTimePeriod(token, 'medium_term');
        await this.getTopTracksForTimePeriod(token, 'short_term');
        await this.sendSongs();
        await this.sendArtists();
    }

    async getTopTracksForTimePeriod(token, time_range) {
       await $.ajax({
            url: "https://api.spotify.com/v1/me/top/tracks?limit=100",
            type: "GET",
            beforeSend: xhr => {
                xhr.setRequestHeader("Authorization", "Bearer " + token);
            },
            data: {
              time_range: time_range,
            },
            success: data => {
                // Checks if the data is not empty
                if (!data) {
                    this.setState({
                        no_data: true,
                    });
                    return;
                }

                var songIds = [];
                for (var i in data.items) {
                  songIds.push(data.items[i].uri );
                }

                this.setState({
                    songs: {...this.state.songs, [`${time_range}`]: songIds},
                    items: this.state.items.concat(songIds),
                    no_data: false
                        /* We need to "reset" the boolean, in case the
                                                   user does not give F5 and has opened his Spotify. */
                });
            }
        });
    }

    getTopArtists(token) {
        // Make a call using the token
        $.ajax({
            url: "https://api.spotify.com/v1/me/top/artists?limit=50",
            type: "GET",
            beforeSend: xhr => {
                xhr.setRequestHeader("Authorization", "Bearer " + token);
            },
            success: data => {
                // Checks if the data is not empty
                if (!data) {
                    this.setState({
                        no_data: true,
                    });
                    return;
                }

                var artistIds = [];
                for (var i in data.items) {
                  artistIds.push(data.items[i].uri );
                }

                this.setState({
                    artists: artistIds,
                    no_data: false
                });
            }
        });
    }

    braidArrays = (arrays) => {
      const braided = [];
      const maxLength = Math.max(...arrays.map((a) => a.length));
      for (let i = 0; i < maxLength; i++) {
        arrays.forEach((array) => {
          if (array[i] !== undefined)
            {
              braided.push(array[i]);
            }
        });
      }
      return [...new Set(braided)];
    };

    sendSongs = async () => {
            await fetch(`${SERVER_API_ADDRESS}api/user_tracks`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                user: this.state.userId,
                songs: this.braidArrays(Object.values(this.state.songs)),
              }),
            })
            .then(this.setState({songsSent: true}));
    }

    sendArtists = () => {
            fetch(`${SERVER_API_ADDRESS}api/user_artists`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                user: this.state.userId,
                artists: this.state.artists
              }),
            })
            .then(this.setState({artistsSent: true}));
    }

    getArtistTopTracks = async (artistId, country) => {
      let topTrackUri = null;
                const response = await fetch( `https://api.spotify.com/v1/artists/${artistId}/top-tracks?country=US`, {
                  method: 'GET',
                  headers: {
                    'Authorization': "Bearer " + this.state.token,
                  }
                })
               .then(async (res)  => {
                    res = await res.json();
                   //let topTrack = res.tracks.reduce((max, track) => max.popularity > track.popularity ? max : track);
                   let topTrack = res.tracks[Math.floor(Math.random()*res.tracks.length)];
                   topTrackUri = topTrack.uri;
                 });
                 return topTrackUri;
    }

    createPlaylist =  () => {
      this.callCreatePlaylist()
        .then(async (res) => {
        let playlistItems = res;
        let playlistTrackIds = [];

        playlistTrackIds = await Promise.map(playlistItems, async (playlistItem) =>{
          if(playlistItem.includes('artist')){
            const artistTopTrackUri = await this.getArtistTopTracks(playlistItem.replace("artist:","").replace("spotify:artist:",""),"US");
            return artistTopTrackUri;
          }else{
            return playlistItem;
          }

        });

        playlistTrackIds = Array.from(new Set(playlistTrackIds));

          const response = await fetch( `https://api.spotify.com/v1/users/${this.state.userId}/playlists`, {
            method: 'POST',
            headers: {
              'Authorization': "Bearer " + this.state.token,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              name: this.state.poolName,
              description: "This playlist was created with spotipool.com.",
              public: true
            }),
          })
          .then(res => res.json())
         .then(async (playlistData)  => {
             await fetch( `https://api.spotify.com/v1/playlists/${playlistData.id}/tracks`, {
               method: 'POST',
               headers: {
                 'Authorization': "Bearer " + this.state.token,
                 'Content-Type': 'application/json',
               },
               body: JSON.stringify({
                 uris: playlistTrackIds,
               }),
             });
             this.setState({playlistUrl: playlistData.external_urls.spotify});

           });
      })

    };

    callCreatePlaylist = async () => {
      let playlistTrackIds = [];
      const setSongsResponse = await fetch(`${SERVER_API_ADDRESS}api/create_playlist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          pool: this.state.poolName,
        }),
      }).then(async (res)  => {
           res = await res.json();
          playlistTrackIds = res.playlistTrackIds;
        });
      return playlistTrackIds;
    }


    leavePool = () => {
      fetch(`${SERVER_API_ADDRESS}api/leave_pool`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: this.state.userId,
          pool: this.state.poolName
        }),
      });
      this.setState({
        poolies: [],
        poolName: null,
        poolId: null,
        playlistUrl: null
      });
      window.localStorage.removeItem('poolName');
    }

    render() {
      let tracks = [];
      if(this.state.token && !this.state.no_data){
        tracks = this.state.items.map((track, key) => <li key = {key}> {track} < /li>);
      }

        return (

          <div className = "App" >
          <WaveGroup/>

            <header className = "App-header" >

            <Logo poolName={this.state.poolName}/>


            {!this.state.token && (
              <div>
              <div className="iframe">
              <iframe width="280" height="158" src="https://www.youtube.com/embed/ErdIbwdSuR8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <a className = "btn btn--loginApp-link"
                href = {`${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL}&scope=${scopes.join("%20"
)}&response_type=token&show_dialog=true`
                    } > Login to Spotify </a>
                    </div>
                )
            }

            {
              // let users = this.state.poolies.map((user, key) => <li key = {key}> {user} < /li>);

                this.state.token && !this.state.no_data &&  this.state.poolId &&(
                    <ul>{tracks}</ul>

                )
            }


            {this.state.token &&
              window.localStorage.getItem('poolName') != null && this.state.poolName == null && (<div> Diving into {window.localStorage.getItem('poolName')} pool...</div>)

            }
            { this.state.connectionError &&
              (<span className="error"> Unfortunately, we are experiencing connection issues. Please try again in a little while. </span>)

            }
            {
              this.state.poolNameError && (<span className="error"> The pool name must only contain letters and numbers. </span>)
            }

            {
                window.localStorage.getItem('poolName') == null && this.state.token && !this.state.no_data && this.state.poolName == null && (
                  <div>
                  <form onSubmit = {this.handleSubmit} >
                  <input className="poolInput" placeholder="Pool name..." type = "text"  onChange = {
                    e => this.setState({
                      post: e.target.value
                    })
                }/>
                <div className="padded-div">
                <button className="btn" type = "submit" > Create / Join Pool < /button> </div>< /form > </div>

              )
            }




            {
              this.state.poolName && (this.state.poolies != null || this.state.poolies != []) &&
              (<table>
                <tbody>
                  <tr>
                    <td>
                    <Poolies poolName={this.state.poolName} users={this.state.poolies} apiAddress={SERVER_API_ADDRESS}/>
                    </td>
                    <td>
                      <PoolControls
                          poolName={this.state.poolName}
                          playlistUrl={this.state.playlistUrl}
                          artistsSent={this.state.artistsSent}
                          songsSent={this.state.songsSent}
                          createPlaylist={this.createPlaylist}
                          leavePool={this.leavePool} />
                    </td>
                  </tr>
                </tbody>
              </table>)
}

             </header>
            </div >
        );
    }


    joinPool = async () => {

      if(this.state.post == null){
        await this.setState({poolNameError: true});
        return;
      }
      let poolPost = this.state.post.trim();
      let regex = /^[A-Za-z0-9]*$/;
      let found = poolPost.match(regex);

      if(!found){
        await this.setState({poolNameError: true});
        return;
      }else{
        await this.setState({poolNameError: false});
      }

      await this.setState({post: this.state.post.trim()});
      ReactGA.pageview("joining pool: " + this.state.post );


      const response = await fetch(`${SERVER_API_ADDRESS}api/join_pool`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          pool: this.state.post,
          userId: this.state.userId,
          displayName: this.state.displayName
        }),
      })
      .catch(async error  =>   {
        await this.setState({post: null, poolName: null, poolies: null, connectionError: true});;
        window.localStorage.removeItem('poolName');
        throw error;
      });

      let body = await response.text();
      let resultObj = JSON.parse(body);
      this.setState({
        poolName: resultObj.pool,
        poolies: resultObj.users,
         connectionError: false
      });
      window.localStorage.setItem('poolName', resultObj.pool);

    }



    handleSubmit = async e => {

      e.preventDefault();
      await this.joinPool();
    };

}

export default App;
