import React, {
    Component
} from "react";

import Waves from "./Waves";

import "./Waves.css";


class WaveGroup extends Component {
  render() {
    return (
      <table style={{width:'100%',height:'80%', position:'absolute', 'pointerEvents': 'none'}}>
      <tbody>
      <tr style={{height:'10pc'}}>
      <td>
      <Waves></Waves>
      </td>
      <td>
      <Waves></Waves>
      </td>
      </tr>
      <tr style={{height:'10pc'}}>
      <td>
      <Waves></Waves>
      </td>
      <td>
      <Waves></Waves>
      </td>
      <td>
      <Waves></Waves>
      </td>
      </tr>
      <tr style={{height:'10pc'}}>
      <td>
      <Waves></Waves>
      </td>
      <td>
      <Waves></Waves>
      </td>
      <td>
      <Waves></Waves>
      </td>
      </tr>
      <tr style={{height:'10pc'}}>
      <td>
      <Waves></Waves>
      </td>
      <td>
      <Waves></Waves>
      </td>
      <td>
      <Waves></Waves>
      </td>
      </tr>
      <tr style={{height:'10pc'}}>
      <td>
      <Waves></Waves>
      </td>
      <td>
      <Waves></Waves>
      </td>
      <td>
      <Waves></Waves>
      </td>
      </tr>
      </tbody>
      </table>
    );
  }
}

export default WaveGroup;
