import React, {
    Component
} from "react";

import "./Logo.css";
import logo from "./assets/floatie.svg";



class Logo extends Component {
  render() {
    return (
<div>
      <div className="logo">
      SP
      <span className="logoSpace">
      <img src = {logo} className = "App-logo" alt = "logo" / >
      </span>
      TIPOOL
      </div>

      {!this.props.poolName && (<div className="instructions"> Log into Spotify and join a pool to create a customized playlist based on everyone's favorite music!</div>)}
</div>

    );
  }
}

export default Logo;
