import React, {
    Component
} from "react";

import "./Waves.css";


class PoolControls extends Component {
  render() {
    return (<div>


      {
        this.props.playlistUrl && this.props.songsSent && this.props.artistsSent && this.props.poolName &&
      <div className="padded-25">
          <a style={{'text-decoration': 'none'}} href={this.props.playlistUrl}  > 
          <div className="btn">
          Open Playlist
          </div>
          < /a>
      </div>
    }
      {!this.props.playlistUrl &&
        this.props.songsSent && this.props.artistsSent && this.props.poolName &&
      <div className="padded-25">
          <button className="btn" onClick = {this.props.createPlaylist} > Create Playlist < /button>
      </div>
    }

    {
      this.props.songsSent && this.props.artistsSent && this.props.poolName &&
      <div>
        <button className="btn-2" onClick = {this.props.leavePool} > Leave Pool </button>
    </div>
  }


      </div>);
  }
}

export default PoolControls;
